import {FooterScreen,Header} from "../shared"

export const AboutScreen=() => {
    return (
        <>
            <Header />
            <div className="fables-header fables-after-overlay bg-rules">
                <div className="container">
                    <h2 className="fables-page-title fables-second-border-color wow fadeInLeft" data-wow-duration="1.5s">About Us</h2>
                </div>
            </div>

            <div className="fables-history-section mb-4 mb-md-5">
                <div className="container">
                    <div className="fables-about-head-style mt-3">
                        <div className="row wow fadeInDown" data-wow-delay=".5s">
                            <div className="col-12 text-center">
                                <h3 className="fables-about-top-head fables-forth-text-color font-15 semi-font d-inline-block bg-white position-relative">
                                    <span className="mx-4">About us</span>
                                </h3>
                                <div className="col-12 col-md-10 offset-md-1">
                                    <h2 className="fables-second-text-color mt-3 font-30 font-weight-bold text-center">We work with clients to solve complex business challenges by building machine learning products, tools, & services  </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-rules mb-4 mb-lg-0 py-3 py-lg-0" style={{backgroundImage: 'url(assets/custom/images/mission-img.jpg)'}} />
            <div className="container-fluid" style={{margin:'10px 0px'}}>
                <div className="row overflow-hidden">
                    <div className="col-12 col-lg-4 p-lg-0">
                        <div className="fables-vision-overlay fables-after-overlay bg-rules">
                            <h2 className="fables-second-text-color my-0 font-30 font-weight-bold position-relative z-index wow fadeInRight" data-wow-duration="2s">Mission</h2>
                            <p className="fables-fifth-text-color position-relative z-index mt-4 mb-4 mb-md-5 wow fadeInRight" data-wow-duration="2s">
                                Develop AI tools and services to solve enterprise data science and operational challenges. Provide deployment ready AI solutions and transformation programs for leading organizations worldwide.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-lg-0">
                        <div className="fables-vision-overlay fables-after-overlay bg-rules">
                            <h2 className="fables-second-text-color my-0 font-30 font-weight-bold position-relative z-index wow fadeInRight" data-wow-duration="2s">Team</h2>
                            <p className="fables-fifth-text-color position-relative z-index mt-4 mb-4 mb-md-5 wow fadeInRight" data-wow-duration="2s">
                                We are an Irvine, CA based consulting team. Our multidisciplinary data scientists and engineers work across computer vision, natural language processing and time series analysis.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 p-lg-0">
                        <div className="fables-vision-overlay fables-after-overlay bg-rules">
                            <h2 className="fables-second-text-color my-0 font-30 font-weight-bold position-relative z-index wow fadeInRight" data-wow-duration="2s">Industries</h2>
                            <p className="fables-fifth-text-color position-relative z-index mt-4 mb-4 mb-md-5 wow fadeInRight" data-wow-duration="2s">
                                Helping clients develop and deploy AI solutions in healthcare, life sciences, defense, retail, financial services and more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterScreen />
        </>
    )
}