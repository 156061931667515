export const FooterScreen=() => {
    return (
        <>
            <div className="fables-footer-image fables-after-overlay white-color py-4 py-lg-5 bg-rules">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-2 mb-5 text-center">
                    <h2 className="font-30 semi-font mb-5">Newsletter</h2>
                    <form className="form-inline position-relative">
                        <div className="form-group fables-subscribe-formgroup">
                            <input type="email" className="form-control fables-subscribe-input fables-btn-rouned" placeholder="Your Email" />
                        </div>
                        <button type="submit" className="btn fables-second-background-color fables-btn-rouned fables-subscribe-btn">Subscribe</button>
                    </form>

                </div> */}
                        {/* <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                    <a href="#" className="fables-second-border-color border-bottom pb-3 d-block mb-3 mt-minus-13"><img src="assets/custom/images/fables-logo.png" alt="fables template" /></a>
                    <p className="font-15 fables-third-text-color">
                        t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                        t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                    </p>

                </div> */}

                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <h2 className="font-20 semi-font fables-second-border-color border-bottom pb-3">Contact us</h2>
                            {/* <div className="my-3">
                        <h4 className="font-16 semi-font"><span className="fables-iconmap-icon fables-second-text-color pr-2 font-20 mt-1 d-inline-block"></span> Address Information</h4>
                        <p className="font-14 fables-fifth-text-color mt-2 ml-4">level13, 2Elizabeth St, Melbourne, Victor 2000</p>
                    </div> */}
                            <div className="my-3">
                                <h4 className="font-16 semi-font"><span className="fables-iconphone fables-second-text-color pr-2 font-20 mt-1 d-inline-block"></span> Call Now </h4>
                                <p className="font-14 fables-fifth-text-color mt-2 ml-4">+1 (262) 893-2992</p>
                            </div>
                            <div className="my-3">
                                <h4 className="font-16 semi-font"><span className="fables-iconemail fables-second-text-color pr-2 font-20 mt-1 d-inline-block"></span> Mail </h4>
                                <p className="font-14 fables-fifth-text-color mt-2 ml-4">support@kovio.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright fables-main-background-color mt-0 border-0 white-color">
                <p className="mb-0">Copyright © Kovio Inc. All rights reserved.</p>
            </div>
        </>

    )
}