import {FooterScreen, Header} from "../shared"

export const ContactScreen=() => {
    return (
        <>
            <Header />
            <div className="fables-header fables-after-overlay">
                <div className="container">
                    <h2 className="fables-page-title fables-second-border-color">Contact Us</h2>
                </div>
            </div>
            <div className="container">
                <div className="row overflow-hidden">
                    <div className="col-12 col-md-4 text-center px-4 px-lg-5 my-4 my-lg-5 wow fadeInDown" data-wow-delay=".5s">
                        <div className="fables-second-border-color border fables-contact-block-border fables-rounded px-2">
                            <span className="fables-iconmap-icon fa-3x fables-second-text-color fables-contact-icon bg-white d-inline-block px-4"></span>
                            <h2 className="font-16 semi-font fables-main-text-color my-3">Address Information</h2>
                            <p className="font-14 fables-forth-text-color">
                                level13, 2Elizabeth St, Melbourne,Victor 2000</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 text-center px-4 px-lg-5 my-4 my-lg-5 wow fadeInDown" data-wow-delay=".8s">
                        <div className="fables-second-border-color border fables-contact-block-border fables-rounded px-2">
                            <span className="fables-iconphone fa-3x fables-second-text-color fables-contact-icon bg-white d-inline-block px-4"></span>
                            <h2 className="font-16 semi-font fables-main-text-color my-3">Mail & Phone number</h2>
                            <p className="font-14 fables-forth-text-color text-truncate">support@kovio.com</p>
                            <p className="font-14 fables-forth-text-color">+1 (262) 893-2992</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 text-center px-4 px-lg-5 my-4 my-lg-5 wow fadeInDown" data-wow-delay="1.1s">
                        <div className="fables-second-border-color border fables-contact-block-border fables-rounded px-2">
                            <span className="fables-iconshare-icon fa-3x fables-second-text-color fables-contact-icon bg-white d-inline-block px-4"></span>
                            <h2 className="font-16 semi-font fables-main-text-color my-3">Stay In Touch</h2>
                            <ul className="nav fables-contact-social-links">
                                <li><a href="#" target="_blank"><i className="fab fa-facebook-f fables-forth-text-color fa-fw"></i></a></li>
                                <li><a href="#" target="_blank"><i className="fab fa-instagram fables-forth-text-color  fa-fw"></i></a></li>
                                <li><a href="#" target="_blank"><i className="fab fa-twitter fables-forth-text-color    fa-fw"></i></a></li>
                                <li><a href="#" target="_blank"><i className="fab fa-linkedin fables-forth-text-color   fa-fw"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row mt-0 mb-4 my-md-5 overflow-hidden">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        <h3 className="font-35 font-weight-bold fables-main-text-color mb-4 text-center">Contact Us</h3>
                        <p className="fables-contact-text fables-forth-text-color text-center">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal </p>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12 wow fadeInLeft">
                        <form className="fables-contact-form">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <input type="text" className="form-control p-3 fables-rounded" placeholder="First Name" />
                                </div>
                                <div className="form-group col-md-6">
                                    <input type="text" className="form-control p-3 fables-rounded" placeholder="Last Name" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <input type="email" className="form-control p-3 fables-rounded" placeholder="Email" />
                                </div>
                                <div className="form-group col-md-6">
                                    <input type="text" className="form-control p-3 fables-rounded" placeholder="Subject" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <textarea className="form-control p-3 fables-rounded" placeholder="Message" rows={4}></textarea>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 text-center">
                                    <button type="submit" className="btn fables-second-background-color 
                         text-white fables-btn-rounded px-7 py-2 font-20">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <FooterScreen/>
        </>
    )
}