import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import {HomeScreen} from './screens';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {AboutScreen} from './screens/about';
import {ContactScreen} from './screens/contact';

function App() {

  const router=createBrowserRouter([
    {
      path: "/",
      element: <HomeScreen />
    }, {
      path: "/about",
      element: <AboutScreen />
    }, {
      path: "/contact",
      element: <ContactScreen />
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
