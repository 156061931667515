import * as React from "react"
import {FooterScreen,Header} from "../shared";

export const HomeScreen=() => {

    React.useEffect(() => {
        // @ts-ignore
        $(".player").mb_YTPlayer();
        //@ts-ignore
        $(".player").YTPPlay();
    },[])

    return (
        <>
            <div className="search-section">
                <a className="close-search" href="#"></a>
                <div className="d-flex justify-content-center align-items-center h-100">
                    <form method="post" action="#" className="w-50">
                        <div className="row">
                            <div className="col-10">
                                <input type="search" value="" className="form-control palce bg-transparent border-0 search-input" placeholder="Search Here ..." />
                            </div>
                            <div className="col-2 mt-3">
                                <button type="submit" className="btn bg-transparent text-white"> <i className="fas fa-search"></i> </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

            <div id="ju-loading-screen">
                <div className="sk-double-bounce">
                    <div className="sk-child sk-double-bounce1"></div>
                    <div className="sk-child sk-double-bounce2"></div>
                </div>
            </div>

            <Header />
            <section className="content-section video-section fables-after-overlay">
                <div className="pattern-overlay">
                    <a id="bgndVideo" className="player" data-property="{videoURL:'https://www.youtube.com/watch?v=205-gOtmlR4',containment:'.video-section', quality:'large', mute: 'true' ,autoPlay:true, opacity:1}">bg</a>
                    <div className="container position-relative z-index">
                        <div className="fables-video-caption text-center wow zoomIn" data-wow-delay="1s" data-wow-duration="2s">
                            <h1 className="white-color font-weight-bold mt-6">Your partner in Generative AI & digital transformation</h1>
                            <p className="fables-fifth-text-color mt-3 mb-4 font-18">
                                We develop AI and Data Science Solutions
                            </p>
                            <a data-fancybox href="https://www.youtube.com/watch?v=205-gOtmlR4">
                                <span className="fables-iconplay fables-second-text-color fa-4x wow bounce"></span>
                            </a>

                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row my-4 my-md-5">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                        <h2 className="font-35 font-weight-bold fables-second-text-color mb-4">What we do</h2>
                        <h5 className="font-30 font-weight-bold fables-second-text-color mb-4">AI Strategy</h5>

                        <p className="fables-forth-text-color">
                            Kovio provides data science consulting and AI services
                        </p>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay=".8s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">

                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Workshops</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Custom business and technical sessions to jump-start or deep-dive into short and long-term AI objectives.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="1.2s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">

                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Data Strategy</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Assess and determine a roadmap and best practices for data, resources, and technical architecture to best execute AI objectives.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay=".4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">

                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">AI Strategy</h2>
                                <div className="font-15 fables-forth-text-color">
                                    We help define data and AI strategy, business processes, use cases and validate emerging technology solutions
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay=".4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">

                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">AI Strategy</h2>
                                <div className="font-15 fables-forth-text-color">
                                    We help define data and AI strategy, business processes, use cases and validate emerging technology solutions
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-4 my-md-5">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                        <h5 className="font-30 font-weight-bold fables-second-text-color mb-4">Data Engineering</h5>
                        <p className="fables-forth-text-color">
                            We help define implement the systems to support machine learning and how the organization will identify, collect, store, process and govern valuable data
                        </p>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Functional Specification</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Defining data architectures, refreshing IT infrastructure and services, to support processing & compute environments for end-to-end AI workloads.
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Engineering & Architecture</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Developing the architecture to support new data structures, storage, ETL, delivery layers, and database management across on-premise and cloud environments.
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">DevOps & HPC Enablement</h2>
                                <div className="font-15 fables-forth-text-color">
                                    We enable and implement NVIDIA DGX GPU or cloud environments necessary to help execute and optimize deployment of AI models.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Data Management</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Implementing modern solutions for data processing, governance, and consumption—from graph databases to monitoring agents.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="row my-4 my-md-5">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                        <h5 className="font-30 font-weight-bold fables-second-text-color mb-4">Data science & AI</h5>
                        <p className="fables-forth-text-color">
                            Developing state of the AI algorithms and performing complex analytics, creating new products, diagnostic tools or services
                        </p>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Pilots & MVPs</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Define success criteria for initial pilots and MVPs with stakeholders to transfer knowledge, validate objectives, & define baseline solutions.
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Exploratory Data Analysis</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Determine data quality and optimal modeling methods and outcomes by augmenting & analyzing datasets.

                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Model Development</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Execute custom algorithm development by training, tuning, testing, & benchmarking the developed ML/DL models.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Production Testing & Evaluation</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Validate & integrate pilot in operational environment, testing and monitoring real-world performance & end-user use.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row my-4 my-md-5">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                        <h5 className="font-30 font-weight-bold fables-second-text-color mb-4">MLOps & Deployment</h5>
                        <p className="fables-forth-text-color">
                            Operationalize a pilot AI stack with full production integration into the organization. Implementation of best practices within DevSecOps & MLPos to continuously support, update and manage AI solutions at scale
                        </p>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Model Deployment</h2>
                                <div className="font-15 fables-forth-text-color">
                                    From managing model integration with MLFlow or Sagemaker to high-speed inferencing with NVIDIA Triton, building an end-to-end solution is critical for real world consumption.

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Production Environment</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Scale a pilot environment leveraging cloud platforms, on-premise data centers, or hybrid environments: We implement the CI/CD, containers, and microservices technologies necessary for enterprise operations.
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Model Management</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Establish a framework to control live AI models and evaluate performance, drift, challenger tuning, cost management, and penetration and security programs. MLOps can establish regulatory compliance and reduced risk throughout the organization.

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 pt-3 pt-md-5 wow zoomIn" data-wow-delay="2.4s" data-wow-duration="1.5s">
                        <div className="row text-center text-md-left">
                            <div className="col-12 col-md-9 pl-md-0">
                                <h2 className="fables-main-text-color font-20 my-2 mt-md-0 semi-font">Production Testing & Evaluation</h2>
                                <div className="font-15 fables-forth-text-color">
                                    Validate & integrate pilot in operational environment, testing and monitoring real-world performance & end-user use.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterScreen />
        </>
    )
}