
export const Header=() => {
    return (
        <>
            <div className="fables-forth-background-color fables-top-header-signin" >
                <div className="container">
                    <div className="row" id="top-row">
                        <div className="col-12 col-sm-2 col-lg-5">
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle border-0 bg-transparent font-13 lang-dropdown-btn pl-0" type="button" id="dropdownLangButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    language
                                </button>
                                <div className="dropdown-menu p-0 fables-forth-background-color rounded-0 m-0 border-0 lang-dropdown" aria-labelledby="dropdownLangButton">
                                    <a className="dropdown-item white-color font-13 fables-second-hover-color" href="#">
                                        <img src="assets/custom/images/england.png" alt="england flag" className="mr-1" /> English</a>
                                    <a className="dropdown-item white-color font-13 fables-second-hover-color" href="#">
                                        <img src="assets/custom/images/France.png" alt="england flag" className="mr-1" /> French</a>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-sm-5 col-lg-4 text-right">
                            <p className="fables-third-text-color font-13"><span className="fables-iconphone"></span> Phone : +1 (262) 893-2992</p>
                        </div>
                        <div className="col-12 col-sm-5 col-lg-3 text-right">
                            <p className="fables-third-text-color font-13"><span className="fables-iconemail"></span> Email: support@kovio.com</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="fables-navigation fables-main-background-color py-3 py-lg-0" >
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10 col-lg-9 pr-md-0">
                            <nav className="navbar navbar-expand-md btco-hover-menu py-lg-2">

                                <a className="navbar-brand pl-0" href="/"><img src="assets/custom/images/kovio-logo.png" alt="Fables Template" className="fables-logo" width={100} /></a>

                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#fablesNavDropdown" aria-controls="fablesNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="fables-iconmenu-icon text-white font-16"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="fablesNavDropdown">

                                    <ul className="navbar-nav mx-auto fables-nav">
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="sub-nav1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Solutions
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="sub-nav1">
                                                <li><a className="dropdown-item" href="#">Computer Vision</a></li>
                                                <li><a className="dropdown-item" href="#">NLP (Natural Language Processing)</a></li>
                                                <li><a className="dropdown-item" href="#">Predictive analysis/time series</a></li>
                                                <li><a className="dropdown-item" href="#">Data engineering</a></li>
                                                <li><a className="dropdown-item" href="#">Model Deployment</a></li>
                                            </ul>
                                        </li>

                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="sub-nav2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Industries
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="sub-nav2">
                                                <li><a className="dropdown-item" href="#">Energy & Utilities</a> </li>
                                                <li><a className="dropdown-item" href="#">Financial Services</a> </li>
                                                <li><a className="dropdown-item" href="#">Healthcare & Life Sciences</a></li>
                                                <li><a className="dropdown-item" href="#">Industrials</a></li>
                                                <li><a className="dropdown-item" href="#">testimonials</a></li>
                                                <li><a className="dropdown-item" href="#">Insurance</a></li>
                                                <li><a className="dropdown-item" href="#">Media & Telecommunications</a></li>
                                                <li><a className="dropdown-item" href="#">Pharmaceuticals & Biotech</a></li>
                                                <li><a className="dropdown-item" href="#">Retail & CPG</a></li>
                                                <li><a className="dropdown-item" href="#">Technology</a></li>
                                                <li><a className="dropdown-item" href="#">Public Sector</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="sub-nav3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Insights
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="sub-nav3">
                                                <li><a className="dropdown-item" href="#">Case Studies</a></li>
                                                <li><a className="dropdown-item" href="#">White Papers</a></li>
                                                <li><a className="dropdown-item" href="#">Blogs</a></li>
                                                <li><a className="dropdown-item" href="#">Press Releases</a></li>
                                                <li><a className="dropdown-item" href="#">Videos</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="sub-nav4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Company
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="sub-nav5">
                                                <li><a className="dropdown-item" href="#">Who we are</a></li>
                                                <li><a className="dropdown-item" href="/about">About Us</a></li>
                                                <li><a className="dropdown-item" href="#">Careers</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link" href="/contact">
                                                Contact
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}